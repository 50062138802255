.slide-show-position {
  padding-top: 50px;
}

.container-carousel {
  margin-top: 60px;
}

.carousel-component-container {
  position: absolute;
  min-width: 90%;
  height: 100%;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  padding: 24px 24px 24px;
}

.cancel-button {
  display: flex;
  width: -webkit-fill-available;
  color: #ffff;
  cursor: pointer;
  background-color: transparent;
  border: none;
  justify-content: flex-end;
}
