.reset-password-container {
  height: 100vh;
  position: relative;
}

.select-language-div-recover {
  position: absolute;
  right: 0;
  top: 0;
  margin: 25px;
}

.form-recover-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recover-pass-ins {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.form-design-recover {
  height: 450px;
}

.first-div-inst {
  margin-top: 17px;
}
.inst-container {
  width: 509px;
  margin-bottom: 23px;
  margin-left: 10px;
}
.inst-container2 {
  margin-bottom: 23px;
  width: 509px;
}

.buttons-container-recover {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.info-password {
  color: rgba(33, 29, 38, 0.6);
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.password-details-strong {
  display: flex;
  flex-direction: column;
}

.is-not-strong-password {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
.is-strong-password {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
