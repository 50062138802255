.input-date-picker-dashboard-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;
  margin-left: 60px;
  margin-top: 16px;
  @media (max-width: 960px) {
    margin-top: 50px;
  }
}

.date-range-picker-design-dashboard {
  background: $contrast_inarix;
  border-radius: 2px;
  width: 191px;
  height: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
  box-shadow: 0px 2.8px 1px -1px $box_shadow_components;
  margin-top: 12px;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}

.button-dashboard-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.activity-chart {
  width: 90%;
  height: 400px;
  justify-content: center;
}

.barley-charts-pos {
  display: flex;
  margin-top: 20px;
  margin-right: 30px;
  justify-content: space-between;
}

.container-charts {
  height: 300px;
  margin-top: 100px;
}
.activity-chart-style {
  padding-left: 60px;
  padding-right: 60px;
}

.barley-wheat-charts-styles {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

.barley-chart-react {
  width: 100%;
  padding: 40px;
  height: 310px;
  gap: 0px;
  margin-bottom: 150px;
}

.rest-of-component-dashboard {
  background: $background_Components;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100vw;
}

.modal-dashboard {
  background-color: red !important;
}

.MuiInputBase-input
  .MuiOutlinedInput-input
  .MuiInputBase-inputAdornedEnd
  .MuiAutocomplete-input
  .MuiAutocomplete-inputFocused {
  margin-top: 10px !important;
}

.loading-views-div {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 15px;
  margin-top: 20% !important;
}

/// new dashboard

.container-tabs {
  margin-right: 60px;
  margin-left: 60px;
  margin-top: -15px;
}

.total-actions-dashboard {
  margin-top: 20px;
  height: 160px;
  background-color: $contrast_inarix;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.number-action-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.01px;
  text-align: left;
}

.number-action-count {
  font-size: 60px;
  font-weight: 300;
  line-height: 72px;
  letter-spacing: -0.5000000596046448px;
  text-align: left;
  margin-top: 10px;
  color: #3478f6;
  @media (max-width: 1255px) {
    display: flex;
    justify-content: center;
  }
}

.container-charts-with-result {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: $contrast_inarix;
  display: flex;
  height: 309.33px;
}

.container-results-charts {
  max-width: 260px;
  padding: 40px;
}

.title-container-results {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.01px;
  text-align: left;
  @media (max-width: 1255px) {
    text-align: center;
  }
}

.title-container-results-mini-charts {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.01px;
  text-align: left;
}

.format-results {
  max-width: 148px;
  gap: 8px;
}

.container-charts-with-result-activity {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: $contrast_inarix;
  display: flex;

  @media (max-width: 1255px) {
    flex-direction: column;
  }
}
.container-results-charts-activity {
  max-width: 260px;
  padding: 40px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1255px) {
    flex-direction: initial;
    justify-content: space-around;
    max-width: none;
  }
}

.dashboard-table-container {
  width: 100%;
  background-color: $contrast_inarix;
  padding: 25px;
  height: 400px;
  padding-bottom: 20px;
}

.title-dashboard-table {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.01px;
  text-align: left;
  margin-bottom: 20px;
}

.pocketlab-container-section {
  background-color: $contrast_inarix;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1330px) {
    flex-direction: column;
    width: 100%;
  }
}

.container-last-version {
  display: flex;
  align-items: center;
  background-color: $contrast_inarix;
  justify-content: space-between;
  margin-top: 40px;
  padding-right: 100px;
  padding-left: 50px;
  padding-top: 40px;
}

.version-container-number {
  display: flex;
}

.chip-version-dashboard {
  width: 46px;
  color: $contrast_inarix;
  height: 23px;
  font-weight: 600;
  font-size: 13px;
  padding: 3px 8px 3px 8px;
  display: flex;
  justify-content: center;
  border-radius: 19px 19px 19px 19px;
  background-color: #68a1ff;
  margin-left: 5px;
}

.version-pocketlab-title-tab {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.01px;
  text-align: left;
}

.background-dashboard {
  background-color: $background_Components;
}

.MuiDataGrid-footerContainer
  .MuiDataGrid-withBorderColor
  .css-1jsywqf-MuiDataGrid-footerContainer {
  align-items: baseline !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  align-items: baseline !important;
  margin-top: 8px !important;
}

.css-uecs51-MuiFormLabel-root-MuiInputLabel-root {
  left: 14px !important;
}
