.input-filter-position-users {
  margin-top: 80px;
  margin-left: 60px;
}

#input-filter-position-us {
  max-width: 100% - 20% !important;
}

.container-tabs-users-tables {
  margin-right: 60px;
  margin-left: 60px;
  margin-top: 30px;
}
.username-title-column {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.mail-title-columns {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  color: var(--Light-Text-Secondary, rgba(33, 29, 38, 0.60));

}
