.title-modal-create-group {
  color: var(--Light-Text-Primary, rgba(33, 29, 38, 0.87));
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  margin-bottom: 16px;
}

.desc-modal-create-group {
  color: var(--Light-Text-Primary, rgba(33, 29, 38, 0.87));
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin-bottom: 24px;
}

.text-field-modal-create-group {
  margin-bottom: 24px;
}

.container-buttons-modal-create-group {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}
