.table-styles-actions {
  width: 55vw !important;
  height: 80vh !important;
  margin-left: 11px !important;
}

.table-styles-actions-open-layout {
  width: 45vw !important;
  height: 80vh !important;
  margin-left: 17px !important;
}

.new-icon-actions {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-chips-scroll {
  overflow-y: scroll;
  height: 400px;
}

.info-individual-action-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 $box_shadow_components;
  transition: 0.3s;
  border-radius: 5px;
  margin-right: 230px;
  margin-bottom: 10px;
  margin-left: 230px;
  background-color: $contrast_inarix;
  &:hover {
    box-shadow: 0 8px 16px 0 $box_shadow_components;
  }
  @media (max-width: 760px) {
    margin-right: 30px;
    margin-left: 30px;
  }
}

.div-conainer-info {
  display: flex;
  justify-content: center;
}

.managing-fetch-action {
  display: flex;
  justify-content: center;
}
.nav-link2 {
  margin-left: 120px;
  color: $main_inarix;
  font-size: 18px;
  transition: 0.1s linear;
  &:hover {
    color: $main_inarix;
    box-shadow: $snackbar_background_inarix;
  }
}

.icon-container-actions {
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-card-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-card-action h2 {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 1px;
}

.title-card-action span {
  font-weight: 700;
  margin-top: 10px;
  font-size: 16px;
  letter-spacing: 1px;
}

.title-card-action .cereal-name-action {
  font-size: 14px;
  padding: 5px;
  border: 2px solid $actions_disabled_inarix;
  border-radius: 15px;
  margin-bottom: 10px;
  border-color: $action_active_inarix;
}
.action-information-container {
  display: flex;
  margin-left: 55px;
}

.containter-steps-inarix {
  margin-left: 90px;
}

.multi-information-container-declare-label {
  display: flex;
  width: 200px;
  margin-left: 305px;
  position: relative;
  top: -45px;
}

.icon-style img {
  width: 30px;
  margin-left: 33px;
}

.icon-style-camera img {
  width: 30px;
  margin-left: 65px;
}

.description-style-one {
  margin-left: 38px;
}

.prediction-slug-description {
  margin-top: 21px;
}
.pictures {
  margin-top: 20px;
}

.prediction-img {
  margin-top: 20px;
  margin-left: 35px;
}

.prediction-slugs {
  margin-right: 30px;
  top: -26px;
}

.group-container-actions h2 {
  font-size: 25px;
  font-weight: 700;
  margin-left: 60px;
}

.table-container-actions {
  display: flex;
  align-items: center;
  margin-top: 100px;
  @media (max-width: 769px) {
    margin-top: 15%;
  }
  @media (max-height: 720px) {
    margin-top: 40px;
  }
}
.table-container-actions-v2 {
  display: flex;
}

.accordion-styles-users-new-actions {
  margin-left: 10px;
  margin-right: 10px;
  width: 35vw;
}

.accordion-styles-users-new-actions h2 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
}

.new-accordion-div {
  height: 500px;
  width: 30vw;
  overflow: scroll;
  overflow-x: hidden;
}

.img-input-remoteid {
  width: 30px;
}

.multi-information-container-prediction {
  display: flex;
  width: 200px;
  margin-left: 305px;
  margin-top: 20px;
  position: relative;
  top: -45px;
}

.multi-declare-label {
  width: 90px;
  display: flex;
  justify-content: center;
  margin-left: 57px;
}

.multi-prediction-div {
  width: 90px;
  display: flex;
  justify-content: center;
  margin-left: 82px;
}

.multi-declare-label-image {
  width: 30px;
}

.external-device-div {
  display: flex;
}

.external-device-text {
  margin-left: 53px;
}
.external-device-img {
  display: flex;
  margin-left: 42.5px;
  margin-right: 23px;
}

.external-device-slug {
  margin-left: 50px;
}

.go-back-data-div {
  margin-bottom: 26px;
  margin-left: 36px;
  margin-top: 52px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: $main_inarix;
  }
}

.arrow-back-data-div {
  color: $main_inarix !important;
  margin-right: 12px !important;
  width: 24px !important;
  height: 24px !important;
}

.active-table {
  color: green;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.archive-table {
  color: brown;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.button-add-users {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10%;
  margin-top: 85px;
}
