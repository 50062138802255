.individual-project-container {
  display: flex;
  justify-content: space-between;
}

.cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 80px;
  height: 100vh;
}

.no-projects {
  font-size: 22px;
  font-weight: 700;
}

.projects-table {
  margin-left: 67px !important;
  margin-right: 67px !important;
  height: 80vh !important;
  background-color: $contrast_inarix !important;
  margin-top: 21px !important;
}

.edit-button-projects {
  cursor: pointer;
  padding-left: 8px;
  width: 40px;
  height: 40px;
  background-color: $background_Components;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 5px 15px -5px $box_shadow_components;
  color: $hover_layout_color;
  overflow: hidden;
  position: relative;
  line-height: 40px;
  font-size: 25px;
  transition: 0.2s linear;
  margin-left: 20%;
  &:hover {
    color: $light_inarix;
  }
}

.no-projects-alert {
  margin-top: 5% !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.arrow-back-projects {
  position: absolute;
  top: 0;
  margin-left: 25px;
}

.grid-foot-container {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: baseline !important;
  align-content: center !important;
  width: 100% !important;
}

.MuiToolbar-root
  .MuiToolbar-gutters
  .MuiToolbar-regular
  .MuiTablePagination-toolbar
  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  align-items: baseline !important;
}
