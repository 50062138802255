.create-sites-container {
  /* top and bottom | left and right */
  margin: 31px 55px 86px 86px;
  height: 88vh;
}

.spacer-div-back-button {
  width: 64px;
}

.top-options-create-locations {
  display: flex;
  justify-content: space-between;
}

.title-step-zero {
  font-size: 24px;
  font-weight: 300;
}

.create-loc-title {
  font-size: 20px;
  font-style: normal;
  line-height: 160%;
  color: #232128;
}

.create-sites-container-steps {
  margin-top: 84px;
  margin-left: 20%;
  margin-right: 20%;
  display: flex;
  flex-direction: column;
}

.div-cards-step-zero {
  &:hover {
    cursor: pointer;
  }
}

///step one
.step-title {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 266%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.step-title-create {
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 133.4%;
  margin-bottom: 24px;
}
.or-span-create-loc {
  margin-left: -12px;
  margin-right: 24px;
}

.container-address-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.steps-container-create-loc {
  margin-top: 48px;
}

.create-site-form {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.create-site-form-2 {
  display: flex;
  flex-direction: column;
}

.input-create-loc-form {
  width: 406px;
  max-width: calc(100% - 20px);
  margin: 0 auto;
  height: 70px;
}

.span-create-loc-above-input {
  width: 406px;
  color: rgba(33, 29, 38, 0.6);
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}

.title-need-new-users {
  color: var(--Light-Text-Primary, rgba(33, 29, 38, 0.87));
  font-feature-settings: "liga" off, "clig" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin-top: 24px;
}
.option-create-users {
  display: flex;
  margin-left: 10px;
}

.background-components-fix {
  background-color: $background_Components;
  position: absolute;
  top: 0;
  left: 0;
  height: -webkit-fill-available;
  width: 100%;
  z-index: -1;
}

.container-address-input {
  width: 450px;
  max-width: calc(100% - 20px) !important;
  height: 58px;
  margin-bottom: 24px;
  margin-top: 15px;
}

.form-label-input-address {
  position: absolute;
  margin-left: 13px;
  width: 100px;
  margin-top: -7px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background-color: #ffff;
  z-index: 2;
}

// ///mapbox

/* Basics */
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
  box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    Arial, Helvetica, sans-serif;
  position: relative;

  width: 100%;
  z-index: 1;
  border-radius: 4px;
  transition: width 0.25s, min-width 0.25s;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl {
  height: 56px !important;
}

.mapboxgl-ctrl-geocoder--input {
  max-width: calc(100% - 20px) !important;
  border-left: 1px solid #bcbcbc !important;
  border-right: 1px solid #bcbcbc !important;
  border-bottom: 1px solid #bcbcbc !important;
  border-top: 1px solid #bcbcbc !important;
  border-radius: 0.25rem !important;
  height: 56px;
  width: 406px;
  border: 0;
  background-color: transparent;
  margin: 0;
  color: #404040;
  color: rgba(0, 0, 0, 0.75);
  padding: 6px 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  position: absolute;
  right: 8px;
  top: 7px;
  display: none;
}

/* Collapsed */
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width 0.25s, min-width 0.25s;
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
  background-color: #fff;
  border-radius: 4px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 110%; /* fallback */
  top: calc(100% + 6px);
  z-index: 1000;
  overflow: hidden;
  font-size: 15px;
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  cursor: default;
  display: block;
  padding: 6px 12px;
  color: #404040;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a,
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  color: #665d5d;
  background-color: #000;
  text-decoration: none;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  fill: #757575;
  top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 16px;
  left: 82%;
  width: 23px;
  height: 23px;
}

.mapboxgl-ctrl-geocoder--button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: #fff;
  line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: #909090;
}

.mapboxgl-ctrl-geocoder--icon-geolocate {
  width: 22px;
  height: 22px;
  margin-top: 6px;
  margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0px;
  -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.mapboxgl-ctrl-geocoder--powered-by {
  display: block;
  float: left;
  padding: 6px 12px;
  padding-bottom: 9px;
  font-size: 13px;
}

.mapboxgl-ctrl-geocoder--powered-by a {
  color: #909090;
}

.mapboxgl-ctrl-geocoder--powered-by a:not(:hover) {
  text-decoration: none;
}

/* Animation */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.design-scope {
  color: var(--Light-Text-Primary, rgba(33, 29, 38, 0.87));
  font-feature-settings: "liga" off, "clig" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%; /* 21.98px */
  letter-spacing: 0.1px;
  margin-bottom: 30px;
}

.container-table-csv-results {
  width: 800px;
  max-width: calc(100% - 20px);
}

.error-find-location-create-location {
  color: #d32f2f;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  width: 120px;
  margin-bottom: 10px;
}
