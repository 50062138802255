.data-export-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
}

.data-export-info-all {
  font-size: 14px;
  font-weight: 400;
  line-height: 20.02px;
  height: 60px;
}

.data-filtered-first-section-styles {
  margin-top: 15px;
  margin-bottom: 10px;
}

.data-filtered-first-section-styles-download {
  font-size: 14px;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.17px;
}

.data-filtered-first-section-styles-button-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.data-filtered-second-section-styles {
  margin-top: 15px;
}

.data-filtered-second-section-styles-email {
  font-size: 14px;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.17px;
}

.data-filtered-second-section-styles-email-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.export-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  padding: 16px;
}

.divider-export-modal {
  width: 409px;
  margin-left: -18px;
  margin-top: 15px;
}

.export-loader-container-progress {
  padding: 24px;
  display: flex;
  align-items: center;
}

.export-loader-container {
  display: flex;
}

.step-2-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 8px;
}

.button-ok-done-export {
  margin-bottom: 5px;
  margin-left: 20px;
  width: 100%;
}
