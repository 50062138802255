@import url("https://rsms.me/inter/inter.css");

* {
  font-family: "Inter", sans-serif;
  font-weight: semi-bold;
  margin: 0px;

  .swal2-shown {
    padding-right: 0% !important;
  }
  .swal2-container {
    z-index: X;
  }
}

.img-alert-delete {
  width: 48px;
  height: 48px;
}

.img-alert-delete-confirm-modal {
  width: 58px;
  height: 58px;
  margin-left: 35px;
}
.errorModal {
  position: absolute;
  max-width: 800px;
  background-color: $contrast_inarix;
  border: 2px solid $contrast_inarix;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px 24px 24px;
}

.confirmModal {
  position: absolute;
  width: 300px;
  background-color: $contrast_inarix;
  border: 2px solid $contrast_inarix;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px 24px 24px;
}

.confirmModal2 {
  position: absolute;
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-content {
  border-radius: 12px;
}
.modal-header {
  border-radius: 12px 12px 0px 0px;
  border-color: #ffff;
}
