/**
 * @author David Roman <david@inarix.com>
 * @file Description
 * @desc Created on 2022-05-03 2:29:37 pm
 * @copyright Inarix
 */

.title-search {
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  color: $primary_text_inarix;
  margin-left: 24px;
  margin-top: 10px;
}

.input-search-location-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
}

.divider-search-sidebar {
  margin-top: 10px;
  margin-bottom: 15px;
  border-top: 0.1px solid $actions_disabled_inarix;
  margin-left: 20px;
  margin-right: 20px;
}

.divider-search-sidebar-locations {
  border-top: 0.1px solid $actions_disabled_inarix;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.position-circular-progress-views-map {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.no-result-styles-sidebar-search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-location-type {
  height: 40px;
  width: 40px;
}
