.modal-detail-user-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  max-height: 100% - 20%;
}
.username-title-modal {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}

.username-subtitle-modal {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
  color: rgba(33, 29, 38, 0.6);
}

.access-and-permissions {
  background: #f5f5f5;
  margin-top: 16px;
  overflow: auto;
}

.access-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
  margin-left: 16px;
  margin-top: 16px;
}

.access-values-users {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.option-left {
  //background-color: red;
  width: 50%;
  letter-spacing: 0.17px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 29, 38, 0.87);
  letter-spacing: 0.17px;
  margin-left: 16px;
}
.title-view-span {
  margin-top: 1px;
}

.option-right {
  //background-color: blue;
  width: 50%;
}
.option-right-list {
  //background-color: green;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.last-value-user-details {
  margin-bottom: 14px;
}

.list-value-user {
  letter-spacing: 0.17px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(33, 29, 38, 0.87);
  letter-spacing: 0.17px;
}

.divider-user-details {
  height: 16px;
  background-color: #ffffff;
}

.buttons-user-view {
  //background-color: aquamarine;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  border-top: 1px solid #e4e4e5;
}

.container-buttons-users-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 24px;
  margin-right: 24px;
}

/// update user
///
///

.spacer-bottom {
  margin-bottom: 16px;
}

.option-left-update {
  width: 100%;
  letter-spacing: 0.17px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 29, 38, 0.87);
  letter-spacing: 0.17px;
  margin-left: 16px;
}
.auto-complete-actions-user {
  margin-top: 16px;
  margin-right: 15px;
}

.option-left-update-autocomplete {
  width: 100%;
  letter-spacing: 0.17px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(33, 29, 38, 0.87);
  letter-spacing: 0.17px;
  margin-left: 16px;
}
