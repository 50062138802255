.btn-styles {
  width: 186.5px !important;
}

.btn-delete-loc {
  height: 44px;
  width: 170px;
}

.general-modal-title .delete-title {
  position: static;
  width: 352px;
  height: 28px;
  left: 0px;
  margin-top: 20px;
  margin-bottom: 8px;
  font-weight: 900;
  font-size: 18px;
  line-height: 28px;
}

.delete-location-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
}

.img-alert-delete {
  width: 48px;
  height: 48px;
}

.container-location-delete {
  display: flex;
  flex-direction: column;
}

.delete-loc-buttons-position {
  display: flex;
  justify-content: space-around;
}

.buttons-position-options {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  height: 45px;
}
