.side-nav {
  position: absolute;
  height: 100vh;
  width: 402px;
  z-index: 1;
  left: 0px;
  top: 0px;
  line-height: 2;
  outline: none;
  background: $contrast_inarix;
  box-shadow: 0px 3px 5px -1px $box_shadow_components,
    0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06);
  animation-name: open;
  animation-duration: 0.5s;
  overflow: auto;
  @media (max-width: 1024px) {
    width: 380px;
  }
}

.side-nav-close {
  height: 100vh;
  width: 0px;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
  background: $contrast_inarix;
  box-shadow: 0 0.2em 1em $box_shadow_components;
  animation-name: close;
  animation-duration: 0.5s;
}

.new-side-bar-off {
  background-color: $contrast_inarix;
  z-index: 2;
  height: 78vh;
  width: 0px;
  position: fixed;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 0px;
  box-shadow: 0 0.2em 1em $box_shadow_components;
}

.container-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
  margin-top: 20px;
  @media (max-width: 1024px) {
    margin-left: 30px;
    margin-top: 15px;
  }
}

.loc-name-side {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  margin-right: 3px;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
}

.loc-name-side-notes {
  color: $primary_text_inarix;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: 4px;
  gap: 10px;
}

.loc-name-side-address {
  font-size: 14px;
  align-items: flex-start;
  margin-right: 9px;
  color: $primary_text_inarix;
}

.loc-name-admin-name {
  color: $primary_text_inarix;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.loc-name-side-email {
  color: $main_inarix;
  font-size: 14px;
  margin-left: 2px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.loc-name-side-phone {
  color: $primary_text_inarix;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.img-loc {
  width: 420px;
  height: 225px;
  object-fit: cover;
  @media (max-width: 1024px) {
    height: 160px;
  }
}

.modify-edit-loc-postion {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
}

.activity-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
}

.activity-charts {
  margin-top: 20px;
  display: flex;
  padding-left: 30px;
  align-self: center;
}

.divider-activity {
  border-top: 2px solid $divider_inarix;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-right: 25px;
  margin-left: 25px;
}

.activity-info {
  display: flex;
  justify-content: space-around;
  letter-spacing: 0.3px;
  font-size: 10px;
  font-weight: 600;
}

.table-with-activity {
  padding-bottom: 5%;
}

.sidenav a:hover {
  color: $p4;
}

.divider-sidebar {
  width: 418px;
  border-top: 1px solid $divider_inarix;
}

.main {
  margin-left: 160px;
  font-size: 28px;
  padding: 0px 10px;
}
.position-buttons-create {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  padding-bottom: 10px;
  @media (max-height: 720px) {
    margin-top: 20px;
    padding-bottom: 30px;
  }
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed $p8;
  background-color: $p4;
  color: $secondary_text_inarix;
  height: 180px;
  margin-bottom: 20px;
  @media (max-height: 853px) {
    height: 150px;
  }
}

.dropzone-info {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

.type-side-bar {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: $disabled_inarix;
  padding-right: 20px;
}

.description-views-sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  margin-left: 15px;
  font-size: 16px;
  color: $primary_text_inarix;
}

.button-position-views-sidebar {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.position-notification-button {
  justify-content: center;
  align-items: center;
  margin-right: 17px;
}

.edit-position-span {
  color: $main_inarix;
  font-weight: 600;
}

.MuiBadge-badge {
  background-color: $notification_color;
}

//// new sidebar again

.MuiDrawer-docked .MuiDrawer-paper {
  height: 100vh;
}

.loading-activity-devices {
  margin-top: 10px;
  margin-left: 100px !important;
}

.minichart-position {
  display: flex;
  margin-left: 35px;
  margin-top: 20px;
}

.list-item-icon-views {
  &:hover {
    color: red !important;
    cursor: pointer !important;
  }
}
