.sample-view-container {
  height: 100vh;
  width: Fixed (1, 324px);
  margin-top: 31px;
  margin-left: 65px;
  margin-right: 65px;
  padding: 24px, 0px, 24px, 0px;
  gap: 24px;
}

.arrow-back {
  width: Fixed (1, 324px);
  height: 48px;
  padding: 8px, 0px, 8px, 0px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
  &:hover {
    color: $main_inarix;
  }
}
.sample-info-container {
  height: 92px;
  display: flex;
  gap: 14px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 29, 38, 0.12);

  @media (max-width: 769px) {
    display: inline-table;
  }
}

.action-buttons-action-view-go {
  display: flex;
  justify-content: space-between;

  width: 30%;
  align-items: center;
  @media (max-width: 769px) {
    width: 100%;
  }
}

.sample-id-title {
  font-size: 34px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0.25px;
  text-align: left;
  @media (max-width: 769px) {
    font-size: 24px;
  }
}

.sample-inarix-id-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  color: #211d2699;
  margin-top: 10px;
}

.left-right-contain {
  display: flex;
  width: 1, 205px;
  gap: 24px;
  background-color: $background_Components;
  @media (max-width: 769px) {
    flex-direction: column;
  }
}

.details-left-side {
  background-color: $background_Components;
  width: 482px;
  margin-top: 24px;
  gap: 24px;
}

.origine {
  width: 482px;
  height: 185px;
  gap: 24px;
}
.action-container {
  margin-top: 24px;
  display: flex;
}

.action-card {
  width: 182px;
  min-height: 120px;
  padding: 13px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 1px 14px 0px #0000004b;
}

.scenario-cereal-type-name {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.4000000059604645px;
  color: #d73a00;
}

.scenario-info-card {
  font-size: 16px;
  font-weight: 590;
  line-height: 21px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;
  color: #fff;
}

.action-info {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  width: 270px;
  height: 24px;
  margin-left: 24px;
}

.photos-container {
  margin-top: 24px;
  width: 384px;
  gap: 24px;
}

.title-sections-sample-view {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.details-right-side {
  width: 603px;
  gap: 24px;
  @media (max-width: 769px) {
    margin-bottom: 10px;
    max-width: 390px;
  }
}

.photo-style-cereal {
  width: 120px;
  height: 120px;
  margin-right: 7px;
  margin-bottom: 7px;
  box-shadow: 0px 1px 14px 0px #0000000f;
  border-radius: 15px;
}
.photos-actions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.photos-actions-2 {
  width: 120px;
  height: 120px;
  margin-right: 7px;
  margin-bottom: 7px;
  box-shadow: 0px 1px 14px 0px #0000000f;
  border: aquamarine;
}

.photos-actions-2:hover .overlay {
  opacity: 1;
}

.overlay {
  position: relative;
  top: -127px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 120px;
  height: 120px;
  opacity: 0;
  transition: 0.5s ease;
  border-radius: 15px;
  background-color: #010f1398;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

/// Right side

.analyse {
  margin-top: 24px;
}

.analyse-card {
  max-width: 603px;
  padding: 16px;
  border-radius: 4px;
  gap: 12px;
  background: #ffffff;
  margin-top: 24px;
  box-shadow: 0px 1px 14px 0px #0000001b;
}

.result-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.analyse-results {
  display: flex;
  margin-top: 10px;
}
.result-1 {
  min-width: 200px;
  @media (max-width: 769px) {
    min-width: 50px;
  }
}

.result-2 {
  margin-left: 70px;
  text-transform: uppercase;
  color: #211d26de;
}

.annotations {
  margin-top: 24px;
}

.annotation-container {
  margin-top: 24px;
}

.annotations-result {
  min-height: 50px;
}

.annotations-result-values {
  display: flex;
  padding-top: 10px;
}

.commentaires {
  margin-top: 24px;
}

.position-info-sampleview-notifications {
  display: flex !important;
  justify-content: space-between !important;
  background: $background_Components !important;
}

.general-info-notification-design {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.created-at-and-option-read {
  color: $main_inarix !important;
}

.card-notification-read {
  margin-top: 24px;
  max-width: 603px;
}

.card-notification-no-read {
  margin-top: 24px;
  max-width: 603px;
  border: $notification_color solid 1px !important;
}

.comments-container {
  height: 370px;
  overflow: scroll;
  overflow-x: hidden;
}

.edit-annotation-but {
  text-transform: lowercase;
  margin-left: 10px;
  color: $main_inarix;
  text-decoration: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

//Card

.creator-action-details {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

//Edit annotations
.result-annotation-1 {
  min-width: 250px;
}

.result-annotation-2 {
  display: flex;
  flex-direction: column;
}

.feat-content-results {
  margin-bottom: 2.5px;
  height: 30.46px;
}

.feat-content-annotation {
  margin-bottom: 3px;
  height: 30px;
  font-weight: 500;
}
.close-annotation {
  margin-bottom: 8px;
}

.close-annotation .close {
  font-size: 35px;
  position: absolute;
  margin-top: -72px;
}

.loading-position {
  display: flex;
  align-items: center;
  justify-content: center;
}

.options-annotations {
  display: flex;
}

.annot-edit {
  text-transform: uppercase;
  margin-top: -10px;
}

.annot-delete {
  text-transform: uppercase;
  margin-bottom: -2px;
}

.annot-delete-span {
  font-size: 12px;
  text-transform: uppercase;
}

.annot-update {
  text-transform: uppercase;
  margin-bottom: -2px;
}

.annot-update-span {
  font-size: 12px;
  text-transform: uppercase;
}
