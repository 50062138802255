.container-locations-section-global {
  display: flex;
  margin-top: -7px;
}

.layout-locations-container {
  display: flex;
  width: 320px;
  max-width: calc(100% - 20px);
  height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: #fff;
  top: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  border-right: 1px solid var(--Light-Other-Divider, rgba(33, 29, 38, 0.12));
}

.title-location-layout {
  color: var(--Light-Text-Primary, rgba(33, 29, 38, 0.87));
  font-size: 20px;
  font-weight: 600;
  line-height: 160%;
}

.subtitle-location-layout {
  color: var(--Light-Text-Secondary, rgba(33, 29, 38, 0.6));
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
}
.buttton-layout-locations-styles {
  margin-top: 16px;
  width: 100%;
}

.general-divider {
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: var(--Light-Other-Divider, rgba(33, 29, 38, 0.12));
  width: 100%;
}

.container-locations-table- {
  width: -webkit-fill-available;
}

.container-options-locations-top-menu {
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  margin-left: 31px;
  margin-right: 32px;
  margin-bottom: 24px; // change later because the input search is disable
}

.top-menu-styles-locations {
  display: flex;
  justify-content: space-between;
}

.tab-title-locations {
  color: var(--Light-Text-Primary, rgba(33, 29, 38, 0.87));
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}

.input-filter-position-locations {
  margin-top: 24px;
}

.table-container-locations {
  background-color: red;
  width: 300px;
}
