/**
 * @author David Roman <david@inarix.com>
 * @overview styles
 * @desc Created on 2021-09-22 1:41:34 pm
 * @copyright Inarix
 */

.overview-panel-div {
  display: flex;
  margin-left: 10px;
  margin-top: 20px;
}

.container-card {
  padding: 2px 16px;
}

.info-option {
  display: flex;
  flex-direction: column;
}

.control-panel-create-loc {
  position: absolute;
  width: 402px;
  height: 100vh;
  left: 0px;
  top: 0;
  right: 10;
  max-width: 402px;
  background: $contrast_inarix;
  box-shadow: 0 2px 4px $box_shadow_components;
  padding: 12px 24px;

  font-size: 13px;
  line-height: 2;
  color: $primary_text_inarix;
  outline: none;
  overflow: auto;
  overflow-x: hidden;
}

.loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

//New side bar styles

@keyframes open {
  0% {
    background-color: $contrast_inarix;
    width: 0px;
  }
  100% {
    background-color: $contrast_inarix;
    width: 430px;
  }
}

@keyframes close {
  0% {
    background-color: $contrast_inarix;
    width: 430px;
  }
  100% {
    background-color: $contrast_inarix;
    width: 0px;
  }
}

.position-button-panel {
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: 5%;
  top: 1.5%;

  @media (max-height: 853px) {
    left: 8.3%;
  }

  @media (max-width: 1450px) {
    left: 6%;
  }
  @media (max-width: 1400px) {
    left: 7.5%;
  }

  @media (max-width: 940px) {
    left: 9%;
  }

  @media (max-width: 769px) {
    left: 11%;
  }
  @media (max-width: 620px) {
    left: 13%;
  }

  @media (max-width: 480px) {
    left: 15%;
  }
}

.position-button-panel-open {
  position: absolute;
  display: flex;
  justify-content: space-between;
  left: 16%;
  top: 1.5%;

  @media (max-width: 1600px) {
    left: 18%;
  }

  @media (max-width: 1450px) {
    left: 21%;
  }
  @media (max-width: 1250px) {
    left: 24%;
  }
  @media (max-width: 1100px) {
    left: 27%;
  }
  @media (max-width: 990px) {
    left: 29%;
  }
  @media (max-width: 890px) {
    left: 32%;
  }

  @media (max-width: 810px) {
    left: 34%;
  }

  @media (max-width: 780px) {
    left: 36%;
  }

  @media (max-height: 853px) {
    left: 21.3%;
  }

  @media (max-width: 769px) {
    left: 38%;
  }
}

.panel-container-options {
  height: 40px;
  display: flex;
  align-items: center;
}

.spacer-options-map {
  width: 410px;
}

.leyend-pos {
  display: flex;
  align-items: center;
}
.leyend-font-style {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.24px;
  text-align: left;
}
.div-leyend {
  width: 7px;
  height: 7px;
  margin-right: 7px;
  border-radius: 100%;
}

.leyend-panel {
  display: flex;
  width: 490px;
  height: 39px;
  top: 13px;
  padding: 8px 16px 8px 16px;
  gap: 10px;
  border-radius: 7px;
  background: $contrast_inarix;
}

.date-range-picker-design {
  background: $contrast_inarix;
  border-radius: 40px;
  width: 191px;
  height: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
  box-shadow: 0px 2.8px 1px -1px $box_shadow_components;

  font-weight: 500;
  cursor: pointer;
}

.date-range-picker {
  margin-left: 100px !important;
}

.date-range-picker-position-map {
  position: absolute !important ;
  margin-right: 2% !important;
  left: 85% !important;
  top: 1.5% !important;
}

// Map box
.map-contain-sidebars {
  display: flex;
}
.new-version-sidebar {
  width: 400px;
  height: 80vh;
}

.map-styles {
  position: relative;
  height: 80vh;
  background-color: $map_background_color;
  margin-top: -10px;
  margin-left: -5px;
  @media (max-width: 769px) {
    margin-top: -45px;
  }
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 16.5px 14px !important;
  padding-bottom: 8px !important;
}

.css-17vdz66 {
  justify-content: center !important;
  color: $primary_text_inarix !important;
  font-weight: 600 !important;
}
