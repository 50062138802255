.table-styles-alone {
  height: 80vh !important;
  background-color: $contrast_inarix !important;
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.fleet-table-container {
  margin-top: 100px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 300px;
  height: 80vh;
}

.subtitles-styles {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
