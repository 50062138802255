.instruction-multi-locations {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.csv-container {
  border-radius: 7px;
  background: var(--Grey-100, #f5f5f5);
  width: 50vw;
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px 24px 16px 24px;
  justify-content: space-between;
}

.tips-container {
  border-radius: 7px;
  background: var(--Grey-100, #f5f5f5);
  width: 65vw;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px 24px 16px 24px;
  justify-content: space-between;
  max-width: 100% - 20%;
}

.my-sites-div {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: var(--Light-Text-Primary, rgba(33, 29, 38, 0.87));
  font-feature-settings: "liga" off, "clig" off;
}

.my-sites-span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: var(--Light-Text-Secondary, rgba(33, 29, 38, 0.6));
  font-feature-settings: "liga" off, "clig" off;
}

.csv-show-button-error-size {
  position: absolute;
  top: 60%;
  left: 0;
  margin-left: 7%;
}
.alert-error-csv {
  position: absolute;
  top: 40%;
  //margin-left: 5%;
}

.title-inarix-tips {
  color: rgba(33, 29, 38, 0.87);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  margin-bottom: 16px;
}

.titles-tips-csv {
  color: rgba(33, 29, 38, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.subtitles-tips-csv {
  color: rgba(33, 29, 38, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.spacer-csv-tips {
  margin-bottom: 16px;
}

.table-csv-example {
  width: 627px;
  max-width: 100% - 20%;
  border: 1px solid black;
  display: flex;
}

.table-csv-results {
  width: 400px;
  max-width: 100% - 20%;
  border: 1px solid black;
  display: flex;
}

.cells-results {
  width: 33.33%;
  font-size: 12px;
  border-right: 1px solid black;
}
.cells {
  width: 33.33%;
  border-right: 1px solid black;
}

.cell-option-csv {
  border-bottom: 1px solid black;
}

.csv-style-container {
  width: 40vw;
}
.csv-container-drag-drop {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.csv-drag-desc {
  display: flex;
}

.drag {
  font-size: 12px;
  color: rgba(33, 29, 38, 0.87);
}

.drag-2 {
  color: $main_inarix;
  text-decoration: underline;
  font-size: 12px;
  &:hover {
    cursor: pointer;
  }
}

.title-csv-error {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.title-csv-incorrect {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.guide-csv-desc {
  display: flex;
}

.guide-csv {
  text-decoration: underline;
  font-size: 13.5px;
  &:hover {
    cursor: pointer;
  }
}
