.snackbar-styles {
  display: flex;
  background: var(--Light-Other-Snackbar, #211d26);
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.17px;
  align-items: center;
  //margin-left: 16px;
  justify-content: center;
  //width: 446px;
  height: 48px;
}

.button-snackbar-general {
  margin-right: 13px;
  margin-left: 13px;
  font-size: 14px;
  //border-right: 1px solid #fff;
}

.delete-snackbar-genera {
  border-left: 1px solid #fff;
  padding-left: 13px;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    color: #ede6e6;
    transition: 0.5s;
  }
}

.selected-snackbar-option {
  border-right: 1px solid #fff;
  padding-right: 13px;
  margin-left: 10px;
  font-size: 13px;
}

.close-snackbar-option {
  margin-right: 16px;
  margin-left: 30px;
  cursor: pointer;
  &:hover {
    color: #ede6e6;
    transition: 0.5s;
  }
}
