@mixin icons-style {
  top: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

@mixin icons-style2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
  margin-left: 10px;
}

@mixin icon-labo-size {
  width: 42px;
  height: 42px;
  border-radius: 4px;
}

@mixin icon-silo-size {
  width: 42px;
  height: 42px;
  border-radius: 64px;
}

.div-icon-result {
  margin-left: -8px;
}

.div-icon-result-no-activity-notif {
  margin-left: 5px;
}

.icon-result-size-silo {
  width: 50px;
  height: 50px;
}

.icon-result-size-silo-2 {
  width: 52px;
  height: 47px;
  margin-right: 2px;
}

.icon-result-size-labo {
  width: 70px;
  height: 70px;
}

.icon-labo-activity {
  background-color: $locationWithActivityBackground;

  @include icons-style();
  @include icon-labo-size();
}

.icon-labo-no-activity {
  background-color: $locationWithoutActivityBackground;

  @include icons-style();
  @include icon-labo-size();
}

.icon-labo-no-activity-create-addLoc {
  background-color: $locationWithoutActivityBackground;

  @include icons-style2();
  @include icon-labo-size();
}



.icon-silo-activity {
  background-color: $locationWithActivityBackground;
  @include icons-style();
  @include icon-silo-size();
}

.icon-silo-no-activity {
  background-color: $locationWithoutActivityBackground;
  @include icons-style();
  @include icon-silo-size();
}

.icon-silo-no-activity-edit-addLoc {
  background-color: $locationWithoutActivityBackground;
  @include icons-style2();
  @include icon-silo-size();
}

.labo-notification {
  flex-direction: column;
}

.notification-icon {
  width: 17px;
  height: 17px;
  position: fixed;
  margin-left: 18px;
  margin-top: 18px;
}

.notification-icon-silo {
  width: 17px;
  height: 17px;
  position: fixed;
  margin-left: 18px;
  margin-top: 18px;
}

.icon-individual {
  width: 16px;
  height: 16px;
}

.icon-individual-silo {
  width: 24px;
  height: 24px;
}
