/*
* @Data sidebar columns scrollbar
*/

.columns-displayed {
  width: 351px;
  height: 400px;
  padding: 24px;
  border-radius: 4px;
  gap: 24px;
}

.switches-data-container {
  height: 295px;
  overflow: scroll;
  padding-top: 15px;
  padding-left: 24px;
  padding-right: 24px;
}

.switches-data-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.switches-data-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.crops-container {
  overflow: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 250px;
  gap: 24px;
}

.crops-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.crops-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.comments-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.comments-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
