/**
 * @author David Roman <david@inarix.com>
 * @file Description
 * @desc Created on 2020-11-19 11:41:09 am
 * @copyright Inarix
 */

///Side MENUS STYLES

.open {
  font-size: 30px;
  @media (max-width: 769px) {
    font-size: 25px;
  }
}
.close-button {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.close {
  cursor: pointer;
  position: absolute;
  font-size: 35px;
}

// Popup map styles

.bar-chart {
  margin-top: 20px;
  display: flex;
  padding-bottom: 5%;
}

.alert-content-users {
  margin-top: -5px;
  margin-bottom: 10px;
  @media (max-width: 769px) {
    margin-bottom: 0px;
  }
}

.alert-content-devices {
  margin-top: -5px;
  margin-bottom: 10px;
  @media (max-width: 769px) {
    margin-bottom: 0px;
  }
}

#map-container canvas {
  cursor: crosshair !important;
}

@mixin popUp-style {
  min-width: 253px;
  height: 78px;
  background-color: $contrast_inarix !important;
  margin-top: -25px;
  cursor: pointer;
  box-shadow: 0px 3px 5px -1px $box_shadow_components;
}

.card-popup {
  border-radius: 10px;
  @include popUp-style();
}

.card-popup-with-image {
  border-radius: 0px 0px 10px 10px;
  @include popUp-style();
}

.img-popup {
  min-width: 253px;
  height: 140px;
  object-fit: cover;
  margin-left: -12px;
  border-radius: 10px 10px 0px 0px;
}

.contain-popup-map {
  display: flex;
  flex-direction: column;
  height: 70px;
  margin-bottom: -40px;
  justify-content: center;
}

.mapboxgl-popup-content {
  min-width: 200px !important;
  width: 253px !important;
  background-color: transparent !important;
  border-style: none !important;
  box-shadow: none !important;
  padding: 10px 12px 20px !important;
  margin-bottom: 25px;
}

.loc-name {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: $primary_text_inarix;
}

.loc-type-pop {
  font-size: 12px;
  font-weight: 400;
  color: $primary_text_inarix;
}
.mapboxgl-popup-tip {
  display: none !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  -webkit-align-self: center !important;
  align-self: center !important;
  border-bottom: none !important;
  border-top-color: $background_Components !important;
  padding-bottom: 10px !important;
  background-color: transparent !important;
}

.css-rob5aa-MuiPaper-root-MuiAlert-root {
  font-size: 11px !important;
}

.icon-chip-overview {
  margin-right: -4px !important;
}

.MuiPickersPopper-root
  .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root
  .MuiPopper-root {
  position: absolute !important;
  top: 50px !important;
  left: 40% !important;
}
