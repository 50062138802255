/**
 * @author David Roman <david@inarix.com>
 * @file new nav styles
 * @desc Created on 2022-02-21 10:56:23 am
 * @copyright Inarix
 */

.avatar-options-user {
  font-size: 12px !important;
}
.logout {
  cursor: pointer;
  &:hover {
    color: $main_inarix;
    box-shadow: $snackbar_background_inarix;
  }
}

.sections-fleet {
  display: flex;
  height: 55px;
  width: 230px;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 150%;
  text-decoration: none !important;
  align-items: center;
  padding-left: 15px;
  border-radius: 10px;
  margin-right: 10px;
  margin-left: -15px;
  font-weight: 600;
  &:hover {
    background-color: $hover_layout_color;
    transition: 0.2 !important;
  }
}

.sections-fleet-close {
  display: flex;
  height: 55px;
  width: 55px;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 150%;
  text-decoration: none !important;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  border-radius: 10px;

  &:hover {
    background-color: $hover_layout_color;
    transition: 0.2 !important;
  }
}

.list-links {
  position: absolute !important;
  left: 5px !important;
  top: 50px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600 !important;
}

.list-links-open {
  margin-left: 26.97px !important;
  position: absolute !important;
  top: 50px;
}
.second-links {
  position: absolute !important;
  left: 20px !important;
  bottom: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.second-links-open {
  position: absolute !important;
  bottom: 0 !important;
  margin-left: 26.97px !important;
}

.sections-fleet-second-links {
  display: flex;
  height: 55px;
  font-size: 14px;
  width: 230px;
  letter-spacing: 0.15px;
  line-height: 150%;
  text-decoration: none !important;
  align-items: center;
  padding-left: 15px;
  padding-left: 18px;
  border-radius: 10px;
  font-weight: 600 !important;
  margin-right: 15px;
  margin-left: -15px;
  &:hover {
    background-color: $hover_layout_color;
    transition: 0.2 !important;
  }
}

.sections-fleet-second-links-close {
  display: flex;
  height: 55px;
  width: 55px;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 150%;
  text-decoration: none !important;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  margin-left: -15px;
  border-radius: 10px;

  &:hover {
    background-color: $hover_layout_color;
    transition: 0.2 !important;
  }
}

.span-account {
  font-size: 14px;
  letter-spacing: 0.15px;
  margin-left: 17px;
  line-height: 150%;
  color: $disabled_button_layout;
}

.account-button {
  display: flex;
  height: 55px;
  width: 55px;
  align-items: center;
  justify-content: center;
  padding-bottom: 7px;
  padding-top: 7px;
  margin-top: 4px;
  cursor: pointer;
  margin-left: -15px;
  border-radius: 10px;
  &:hover {
    background-color: $hover_layout_color;
    transition: 0.2;
  }
}

.account-button-open {
  display: flex;
  height: 55px;
  width: 230px;
  cursor: pointer;
  align-items: center;
  border-radius: 10px;
  padding-left: 15px;
  margin-top: 4px;
  margin-right: 15px;
  margin-left: -15px;
  font-weight: 600;
  &:hover {
    background-color: $hover_layout_color;
    transition: 0.2;
  }
}

.logo-layout {
  left: 26.97px;
  position: absolute;
  width: 27px;
  height: 18px;
}

.title-section-styles {
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  color: $primary_text_inarix;
  margin-left: 65px;
  margin-top: 88px;
  margin-bottom: -50px;
  @media (max-height: 720px) {
    margin-top: 44px;
    margin-bottom: 0px;
  }
}

.img-layout-log {
  margin-left: -0px;
  margin-right: 20px;
  padding-bottom: 5px;
}

.box-components{
  height: auto 100% !important;
}