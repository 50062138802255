
.division-create-loc-edit {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon-division-create-edit {
  margin-right: 12px;
  color: $main_inarix;
  margin-top: -3px;
}

.span-division-create-edit-loc {
  color: $primary_text_inarix;
}

.division-create-loc-edit-contact {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.position-lat-long-inputs {
  display: flex;
}

.solid-create-modify-loc {
  border-top: 2px solid $contrast_inarix;
  margin-top: 2px;
  width: 408px;
  margin-left: -30px;
  margin-bottom: 10px;
  position: absolute;
}

.img-drop-zone-edit {
  width: 100px;
  height: 100px;
  top: 110px;
  left: 10px;
  border-radius: 10px;
  position: absolute;
}


.type-locatins-styles-container{
  display: flex;
}