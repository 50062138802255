/**
 * @author David Roman <david@inarix.com>
 * @file Description
 * @desc Created on 2020-12-04 12:01:15 pm
 * @copyright Inarix
 */

body {
  margin: 0;
}

.bg {
  background-image: url("https://res.cloudinary.com/inarix/image/upload/v1672762475/Login%20inarix%20v2/grains-bg_pnhrab.jpg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 769px) {
    height: 100vh;
  }
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 20px;
  text-align: center;
  @media (max-width: 769px) {
    padding: 0px;
  }
}
.logo-design {
  display: flex;
  justify-content: center;
}

.logo-design img {
  width: 300px;
}

.message-design {
  margin-bottom: 90px;
  margin-left: 350px;
  margin-right: 350px;
  @media (max-width: 769px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.user-card {
  height: 400px;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  background: $actions_disabled_inarix;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 $box_shadow_components,
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.brand-logo-container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background: rgba(100, 100, 100, 0.5);
  padding: 10px;
  text-align: center;
}
.brand-logo-container img {
  border-radius: 50%;
}
.brand-logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid $contrast_inarix;
}
.form-container {
  margin-top: 100px;
}
.login-btn {
  width: 100%;
  background: $rating_active_inarix !important;
  color: $contrast_inarix !important;
}
.login-btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login-container {
  padding: 0 2rem;
}
.input-group-text {
  background: $rating_active_inarix !important;
  color: $contrast_inarix !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input-user,
.input-pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.remember-me {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
}

.remember-me-checkbox {
  margin-left: 2px;
}

.remember-me-text {
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 15px;
}

.buttons {
  margin-top: -10px;
}

.btn-trans input {
  height: 20px;
  width: 20px;
  z-index: 2;
}

.btn-trans-mex {
  height: 22px;
  width: 22px;
  z-index: 2;
  margin-top: -5px;
}

/// login v2

.login-container-v2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.container-button-login {
  display: flex;
  margin-top: 24px;
}

.version-portal {
  position: absolute;
  margin-top: 10px;
  font-size: 12px;
}

.alert-content-login {
  position: absolute;
  width: 305px;
  margin-left: 90px;
  max-width: 408px;
  min-width: 100px;
  margin-top: -28px;
  margin-bottom: 10px;
  margin-right: 10px;
  @media (max-width: 769px) {
    margin-bottom: 0px;
  }
}

.button-active-label-v2-disable {
  width: 5px;
  height: 5px;
}

.button-active-label-v2-active {
  width: 5px;
  height: 5px;
  background-color: green;
}

/// new login

.login-v2-container {
  height: 100vh;
  display: flex;
}

.login-form {
  width: 50%;
  position: relative;

  @media (max-width: 920px) {
    width: 100%;
  }
}

.img-form {
  width: 50%;
  background-image: url("../images/Rectangle\ 193.png");
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 920px) {
    display: none;
  }
}

.select-language-div {
  margin: 24px 25px 0 0;
  position: absolute;
  top: 0;
  right: 0;
}

.inarix-icon-position {
  align-items: center;
  margin-left: 154px;
  margin-right: 154px;
  @media (max-width: 644px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-height: 757px) {
    margin-top: 0px;
  }
}
.inarix-icon-design {
  width: 80px;
  height: 80px;
  background-color: black;
  padding: 13.3px;
  border-radius: 14.545px;
  @media (max-height: 604px) {
    width: 60px;
    height: 60px;
  }
}

.title-form-login {
  margin-top: 48px;
  color: #000;
  font-size: 34px;
  font-style: normal;
  font-weight: 300;
  line-height: 123.5%;
  letter-spacing: 0.25px;
  @media (max-height: 604px) {
    margin-top: 38px;
    font-size: 30px;
  }
  @media (max-height: 550px) {
    margin-top: 20px;
    font-size: 30px;
  }
}

.subtitle-form-login {
  margin-top: 17px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.15px;
  @media (max-height: 604px) {
    margin-top: 14px;
    font-size: 14px;
  }
}

.form-login-data {
  margin-top: 48px;
  @media (max-height: 604px) {
    margin-top: 17px;
  }
}
.reset-password {
  margin-top: 5px;
  margin-left: 10px;
  color: $light_inarix;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration: none;
  &:hover {
    color: $p50;
    text-decoration: none;
  }
}

.version-position {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--Light-Text-Secondary, rgba(33, 29, 38, 0.6));
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
  margin: 10px;
}

.snackbar-position {
  position: absolute;
  bottom: 0;
}

.container-info-login-alpha {
  z-index: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#language-label {
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 1px;
  }
  .css-14lo706 {
    width: -webkit-fill-available !important;
  }
}
